<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
      <div class=" mb-4">
          <h2 class="text-left" style="text-transform: capitalize;" >{{table}}</h2>
      </div>
    <div class="container-fluid p-0 mb-3">
      <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Tarjeta</button>
    </div>

    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
              <template #cell(fecha_inicio)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_inicio).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(fecha_fin)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(action)="data">
                  <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
                  <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>
              </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
          </b-card>
      </template>
      </b-table>
        <div>
          
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>


    
  <b-modal ref="modal-item2" hide-footer >
    <template #modal-title>
      <h3>{{modalText}}</h3>
    </template>

    <template>
      <form @submit.prevent="saveData()">
        <div class="row">
          <div class="col-12">
            <label for="">Nombre</label>
            <input required class="form-control" v-model="row.titulo" type="text" name="" id="">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Descripción</label>
            <input required class="form-control" v-model="row.descripcion" type="text" name="" id="">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Categoría</label>
            <!-- <input class="form-control" v-model="row.urlimagen" type="text" name="" id=""> -->
              <b-form-select required v-model="row.categoria_id" class="mb-3 form-control">
                  <b-form-select-option class="text-center" v-for="item of categorias" 
                  :value="item.id" :key="item.id">{{ item.nombre }} </b-form-select-option>
              </b-form-select>            
          </div>
        </div>        
        <!-- <div class="row mt-1">
          <div class="col-12">
            <label for="">Imagen</label><br>
            <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('subcategorias')"/><br>
            <input class="form-control" v-model="row.urlimagen" type="text" name="" id="">
          </div>
        </div> -->
        <div class="row mt-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
            <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>  
  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      selected: null,
        categorias: [
        ],
        Producto: [
          { id: 1, text: 'Producto' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
      perPage: 25,
      currentPage: 1,
      filter: null,

      table: "subcategorias",
      singleTable: "subcategoría",
      tableSpanish: "subcategorias",
      modalText: 'Nueva Ficha',
      title: 'Sub categorías',
      fields: [
        { key: "id", label: "Código" },
        { key: "name", label: "Nombre" },
        { key: "rolId", label: "rol" },
        { key: "username", label: "usuario" },
        { key: "storeId", label: "tienda" },
        { key: "actions", label: "Acciones" },
      ],
      items: [],
      roles:[],
      subRoles: [],
      stores:[],
      selectedItem: {},

      showDetails: false,

      formFields: [
        { label: "Nombre", key: "name", required: true, value: "" },
        { label: "Rol", key: "rolId", required: true, value: "" },
        { label: "Usuario", key: "username", required: true, value: "" },
        { label: "Contraseña", key: "password", required: true, value: "" },
        { label: "Tienda", key: "storeId", required: true, value: "" },
      ],
      method: "post",
      table_list: [
        // { id: 1 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 2 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 3 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 4 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 5 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
      ],
      row:{},
      categoria_id: 1,
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'categoria', label: 'Categoría', sortable: true, class: 'text-center' },
          { key: 'titulo', label: 'Nombre', sortable: true, class: 'text-center' },
          // { key: 'fecha_inicio', label: 'fecha inicio', sortable: true, class: 'text-center' },
          // { key: 'fecha_fin', label: 'fecha fin', sortable: true, class: 'text-center' },
          // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      file1: null,
      filename1: null,
    };
  },
  async mounted() {
    this.getData();
    this.getcategorias();

  },
  methods: {
    onFileSelected(type, item){
      this.file1 = event.target.files[0];
      this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
      this.filename1 = type + '_' + this.filename1+ '.jpg';
      console.log(type,',',item)
    },    
    showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getcategorias() {
      try {
        let res = await this.$store.dispatch("get", { path: "categorias/getAll/"});
        console.log(res)
        if(res.length>0){
          this.categorias = res
        }
        else{
          this.categorias = []
        }
      } catch (error) {
        this.categorias = []
        alert(error);
      }
    },
    async getData() {
      try {
        this.$isLoading(true)
        let res = await this.$store.dispatch("get", { path: this.table + '/getAllSub/'});
        this.$isLoading(false)        
        console.log(res)
        if(res.length>0){
          this.table_list = res
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.$isLoading(false)        
        this.table_list = []
        this.totalRows = this.table_list.length
        alert(error);
      }
    },
    async saveData() {
      try {
        let req
        let uploadImg
        this.$isLoading(true)        
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.urlimagen = '../img/' + this.filename1
          }
        }
        console.log(this.row)
        delete this.row.categoria
        if(this.row.id){
          req = await this.$store.dispatch("put", {
            path: this.table + '/modify/'+ this.row.id,
            data: this.row
          });
        }
        else{
          req = await this.$store.dispatch("post", {
            path: this.table+'/new',
            data: this.row
          });
        }
        this.$isLoading(false)        
        console.log('reqq',req)
        //this.showDetails = false;
        this.$refs['modal-item2'].hide()
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$isLoading(false)        
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });                
        
      }
    },
    ///////////////

    async deleteItem( item ) {
      try {
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }
        this.$isLoading(true)
        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$isLoading(false)
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        alert(error);
      }
    },

  },
};
</script>

<style>

 

</style>